import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "../../../../ui-treact/components/misc/Headings.js";
import TeamIllustrationSrc from "src/images/team.jpg";
import { Link } from "../../../../@gatsbystorefront/.gatsby-theme-storefront-shopify/utils/general";
import Briefcase from "../svgs/Briefcase.js";
import Dollar from "../svgs/Dollar.js";
import ScrollAnimation from 'react-animate-on-scroll';
import "src/theme/styles/animate.min.css"; 

const Container = tw.div`relative w-full`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-8`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.text_on_left ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-800`;

const Features = tw.div`mt-8 max-w-sm mx-auto md:mx-0`;
const Feature = tw.div`mt-8 flex items-start flex-col md:flex-row`;

const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-primary-500 text-center rounded-full p-2 flex-shrink-0`}
  svg {
    ${tw`w-5 h-5 text-primary-500`}
  }
`;

const FeatureText = tw.div`mt-4 md:mt-0 md:ml-4 text-center md:text-left`;
const FeatureHeading = tw.div`font-bold text-lg text-primary-500`;
const FeatureDescription = tw.div`mt-1 text-sm`;
// const Div= styled.div``;
// const PrimaryButton = styled(Link)`${tw` mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0 px-8 py-3 font-bold rounded bg-primary-900 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`}`

export default ({data,page}) => {
    let finalData= data?.schema_json;  
    let content= {
        sub_heading: "Our Expertise",
        heading: (
          <>
            We have the most <span tw="text-primary-500">professional</span> team.
          </>
        ),
        description: "Teamwork is the ability to work together toward a common vision. The ability to direct individual accomplishments toward organizational objectives. It is the fuel that allows common people to attain uncommon results.",
        primary_button_text: "See Our Portfolio",
        primary_button_url: "https://timerse.com",
        text_on_left: true,
        features:[
            {
                Icon: Briefcase,
                title: "Professionalism",
                description: "We have the best professional marketing people across the globe just to work with you."
              },
              {
                Icon: Dollar, 
                title: "Affordable",
                description: "We promise to offer you the best rate we can - at par with the industry standard."
              }
        ],
       ...finalData
  } 


  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  /*
   * Change the features variable as you like, add or delete objects
   * `icon` must be a React SVG component. See how BriefcaseIcon is imported above. For a full list of available icons, see Feather Icons.
   */

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={TeamIllustrationSrc} />
        </ImageColumn>
        <TextColumn text_on_left={content.text_on_left}>
          <TextContent>
            <Subheading>{content.sub_heading}</Subheading>
            <Heading>{content.heading}</Heading>
            <Description>{content.description}</Description>
            <Features>
              {content.features.map((feature, index) => (
                <ScrollAnimation animateIn='fadeInUp' delay={index*150} animateOnce={true}>
                <Feature key={index}> 
                  <FeatureIconContainer>{<feature.Icon />}</FeatureIconContainer>
                  <FeatureText>
                    <FeatureHeading>{feature.title}</FeatureHeading>
                    <FeatureDescription>{feature.description}</FeatureDescription>
                  </FeatureText>
                </Feature>
                </ScrollAnimation>
              ))}
            </Features>
            {/* <PrimaryButton as="a" href={content.primary_button_url}>
              {content.primary_button_text}
            </PrimaryButton> */}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
